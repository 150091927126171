<!--
 * @Author: duanxin
 * @Date: 2023-07-27 10:50:13
 * @LastEditors: duanxin
 * @LastEditTime: 2023-08-11 17:48:04
 * @Description:
-->
<template>
  <div class="select-option-container">
    <el-select
      :value="modelValue"
      :size="size"
      :filterable="filterable"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="$emit('change', formatVal($event, modifier))"
    >
      <slot></slot>
      <el-option
        v-for="item in data"
        :value="item[value]"
        :label="item[label]"
        :key="item[value]"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "a-select-option",
  components: {},
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    modelValue: [String, Number],
    filterable: Boolean,
    data: Array, // options列表
    label: {
      // 对应select-option中label属性
      default: "",
    },
    value: {
      // 对应select-option中value属性
      default: "",
    },
    disabled: Boolean, // 禁用状态
    modifier: {
      // modelValue格式化方式（例如：trim等）
      default: "",
    },
    placeholder: String,
    size: String, // 输入框尺寸
  },
  data() {
    return {};
  },
  filters: {},
  watch: {},
  computed: {},
  methods: {
    formatVal(value, type) {
      return value[type]?.() || value;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
};
</script>

<style lang="stylus" scoped>

</style>
